import { pascalToSnake } from './string'

// eslint-disable-next-line no-useless-escape
const queryValueRegex = /^[a-zA-Z0-9_\-]+=[a-zA-Z0-9_\-,]+$/
const isValidQueryValue = (query) => queryValueRegex.test(query)

export const queryBuilder = (...queries) => {
  let queryCompleted = queries.reduce((acc, newQuery, index) => {
    if (!newQuery) {
      return acc
    }
    const query = newQuery.trim()

    query.split('&').forEach((q) => {
      if (!isValidQueryValue(q)) {
        throw new Error(`Invalid query value: ${q}`)
      }
    })

    if (index === 0) {
      return `?${query}`
    }

    return `${acc}&${query}`
  }, '')
  if (queryCompleted && queryCompleted[0] !== '?')
    queryCompleted = '?' + queryCompleted.substring(1)
  return queryCompleted
}

export const farmIdQuery = (id) => (id ? `farm_id=${id}` : '')
export const cropIdQuery = (id) => (id ? `crop_id=${id}` : '')
export const seasonCropIdQuery = (id) => (id ? `season_crop_id=${id}` : '')
export const sameNameParamQuery = (param) => {
  for (const key in param) {
    if (Array.isArray(param[key]) && param[key].length) {
      return `${pascalToSnake(key)}=${param[key].join(',')}`
    } else if (!Array.isArray(param[key]) && param[key]) {
      return `${pascalToSnake(key)}=${param[key]}`
    }
    return ''
  }
}

export const startPeriodQuery = (date) => (date ? `start_period=${date}` : '')
export const endPeriodQuery = (date) => (date ? `end_period=${date}` : '')
export const periodQuery = (start, end) =>
  queryBuilder(startPeriodQuery(start), endPeriodQuery(end)).substring(1)
