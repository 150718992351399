import api from '@/services/api'
import { crops } from '@/store/crops'

function initialState() {
  return {
    diseases: [],
    seasonsCrops: [],
    currentSeasonCrop: null,
  }
}

export const seasonsCrops = {
  namespaced: true,

  state: {
    initialState: initialState,
    diseases: initialState().diseases,
    seasonsCrops: initialState().seasonsCrops,
    currentSeasonCrop: initialState().currentSeasonCrop,
  },

  getters: {
    seasonsCrops: (state) => {
      return state.seasonsCrops
    },
    currentSeasonCrop: (state) => {
      return state.currentSeasonCrop
    },
    activePeriod: (state) => {
      let clonedSeasons = state.seasonsCrops
        .slice()
        .sort((seasonA, seasonB) => {
          return new Date(seasonA.start_period) - new Date(seasonB.start_period)
        })

      const firstSeason = clonedSeasons[0]
      const lastSeason = clonedSeasons.reverse()[0]

      return {
        start_period: firstSeason.start_period,
        end_period: lastSeason.end_period,
      }
    },
    enabledCrops: (state) => {
      const currentSeasonId = state.currentSeasonCrop.season_id
      const currentSeasonCrops = state.seasonsCrops
        .filter(({ season_id }) => season_id == currentSeasonId)
        .map(({ crop_id }) => crop_id)
      return crops.state.crops.filter(({ id }) =>
        currentSeasonCrops.includes(id)
      )
    },
    seasonCropsDiseases: (state) => {
      return state?.diseases?.filter(
        (disease) => disease.crop_id == crops.state.currentCrop.id
      )
    },
  },

  mutations: {
    SET_SEASONS_CROPS(state, seasonsCrops) {
      state.seasonsCrops = seasonsCrops
    },
    SET_CURRENT_SEASON_CROP(state, currentSeasonCrop) {
      state.currentSeasonCrop = currentSeasonCrop
    },
    SET_DISEASES(state, diseases) {
      state.diseases = diseases
    },
  },

  actions: {
    async fetchSeasonsCrops({ commit }, farmId) {
      const response = await api.seasonsCrops.getSeasonsCrops(farmId)
      await commit('SET_SEASONS_CROPS', response.data)
    },
    async fetchDiseases({ commit, state }) {
      const currentSeasonCrop = state.currentSeasonCrop?.id
      try {
        const { data } = await api.diseases.getBySeasonCrop(currentSeasonCrop)
        commit('SET_DISEASES', data)
      } catch (e) {
        throw new Error('Erro ao buscar diseases')
      }
    },
    setCurrentSeasonCrop({ commit }, seasonCrop) {
      commit('SET_CURRENT_SEASON_CROP', seasonCrop)
    },
    resetStore({ commit }) {
      commit('SET_SEASONS_CROPS', initialState().diseases)
      commit('SET_CURRENT_SEASON_CROP', initialState().seasonsCrops)
      commit('SET_DISEASES', initialState().currentSeasonCrop)
    },
  },
}
