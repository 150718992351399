import { BRAZIL } from '@/utils/countries'

const seasonCrops = [
  {
    id: 1,
    crop_id: 1,
    season_name: '2022/23',
    season_id: 6,
    season_active: false,
    country_id: BRAZIL,
    start_period: '2022-08-01',
    end_period: '2023-05-31',
  },
  {
    id: 2,
    crop_id: 2,
    season_name: '2022',
    season_id: 6,
    season_active: false,
    country_id: BRAZIL,
    start_period: '2022-01-01',
    end_period: '2022-09-30',
  },
  {
    id: 3,
    crop_id: 1,
    season_name: '2023/24',
    season_id: 7,
    season_active: true,
    country_id: BRAZIL,
    start_period: '2023-08-01',
    end_period: '2024-05-31',
  },
  {
    id: 4,
    crop_id: 2,
    season_name: '2023',
    season_id: 7,
    season_active: true,
    country_id: BRAZIL,
    start_period: '2023-01-01',
    end_period: '2023-09-30',
  },
  {
    id: 5,
    crop_id: 1,
    season_name: '2024/25',
    season_id: 8,
    season_active: true,
    country_id: BRAZIL,
    start_period: '2024-08-01',
    end_period: '2025-05-31',
  },
  {
    id: 6,
    crop_id: 2,
    season_name: '2024',
    season_id: 8,
    season_active: true,
    country_id: BRAZIL,
    start_period: '2024-01-01',
    end_period: '2024-09-30',
  },
]

export { seasonCrops }
