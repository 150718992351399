export const removeSpecialCharacters = (string) => {
  return string
    .replace('Á', 'A')
    .replace('Â', 'A')
    .replace('Ã', 'A')
    .replace('À', 'A')
    .replace('É', 'E')
    .replace('Ê', 'E')
    .replace('Í', 'I')
    .replace('Ó', 'O')
    .replace('Ô', 'O')
    .replace('Õ', 'O')
    .replace('Ú', 'U')
    .replace('Ç', 'C')
}

export const isString = (word) => {
  return typeof word === 'string' || word instanceof String
}

export const pascalToSnake = (word) => {
  return word
    .split(/\.?(?=[A-Z])/)
    .join('_')
    .toLowerCase()
}
