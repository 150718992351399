import {
  getExecutedSowings,
  getPlanningSowingsWithoutDuplicated,
} from '@/services/__mocks__/sowingsSeeds'
import { catalogFungicides } from '@/modules/catalog/mock/catalogSeeds'
import { getCropNameById } from '@/utils/crops'
import { getCustomDate } from '@/utils/time'
import {
  getRandomEfficacies,
  seasonDiseases,
} from '@/services/__mocks__/diseasesSeeds'

// planning
const variety1PlanningSowings = getPlanningSowingsWithoutDuplicated().filter(
  (sowing) => sowing.variety_id == 1
)
const variety7PlanningSowings = getPlanningSowingsWithoutDuplicated().filter(
  (sowing) => sowing.variety_id == 7
)

const planningSprayings = [
  {
    id: 1,
    program_id: 1,
    sowing_id: null,
    ideal_date: getCustomDate(11),
    applied_date: '',
    type: 'disease',
    spraying_order: 1,
    main_disease_id: 1,
    secondary_disease_id: null,
    products: [
      {
        id: catalogFungicides[0].pesticide_id,
        name: catalogFungicides[0].pesticide,
        dose_per_hectare: 8.53,
        price_per_liter: 3.7,
      },
      {
        id: catalogFungicides[1].pesticide_id,
        name: catalogFungicides[1].pesticide,
        dose_per_hectare: 5.3,
        price_per_liter: 2,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety1PlanningSowings[0].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety1PlanningSowings[0].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety1PlanningSowings[0].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety1PlanningSowings[0].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety1PlanningSowings[0].crop_id).split('.')[1]
      ][4],
      seasonDiseases[
        getCropNameById(variety1PlanningSowings[0].crop_id).split('.')[1]
      ][5],
      seasonDiseases[
        getCropNameById(variety1PlanningSowings[0].crop_id).split('.')[1]
      ][6],
    ]),
  },
  {
    id: 2,
    program_id: 2,
    sowing_id: null,
    ideal_date: getCustomDate(5),
    applied_date: '',
    type: 'disease',
    spraying_order: 1,
    main_disease_id: 5,
    secondary_disease_id: null,
    products: [
      {
        id: catalogFungicides[1].pesticide_id,
        name: catalogFungicides[1].pesticide,
        dose_per_hectare: 3.58,
        price_per_liter: 7.3,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety7PlanningSowings[0].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety7PlanningSowings[0].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety7PlanningSowings[0].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety7PlanningSowings[0].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety7PlanningSowings[0].crop_id).split('.')[1]
      ][4],
    ]),
  },
]

// executed
const variety1ExecutedSowings = getExecutedSowings().filter(
  (sowing) => sowing.variety_id == 1
)
const variety2ExecutedSowings = getExecutedSowings().filter(
  (sowing) => sowing.variety_id == 2
)
const variety8ExecutedSowings = getExecutedSowings().filter(
  (sowing) => sowing.variety_id == 8
)

const executedSprayings = [
  {
    id: 100,
    program_id: 3,
    sowing_id: variety1ExecutedSowings[0].id,
    ideal_date: getCustomDate(10),
    applied_date: getCustomDate(15),
    type: 'disease',
    spraying_order: 1,
    main_disease_id: 1,
    secondary_disease_id: null,
    products: [
      {
        id: catalogFungicides[0].pesticide_id,
        name: catalogFungicides[0].pesticide,
        dose_per_hectare: 1.23,
        price_per_liter: 20,
      },
      {
        id: catalogFungicides[1].pesticide_id,
        name: catalogFungicides[1].pesticide,
        dose_per_hectare: 2.4,
        price_per_liter: 10,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][4],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][5],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][6],
    ]),
  },
  {
    id: 101,
    program_id: 3,
    sowing_id: variety1ExecutedSowings[0].id,
    ideal_date: getCustomDate(14),
    applied_date: '',
    type: 'disease',
    spraying_order: 2,
    main_disease_id: 3,
    secondary_disease_id: 1,
    products: [
      {
        id: catalogFungicides[2].pesticide_id,
        name: catalogFungicides[2].pesticide,
        dose_per_hectare: 3,
        price_per_liter: 15,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][4],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][5],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][6],
    ]),
  },
  {
    id: 102,
    program_id: 3,
    sowing_id: variety1ExecutedSowings[0].id,
    ideal_date: getCustomDate(-10),
    applied_date: '',
    type: 'disease',
    spraying_order: 3,
    main_disease_id: 1,
    secondary_disease_id: 4,
    products: [
      {
        id: catalogFungicides[3].pesticide_id,
        name: catalogFungicides[3].pesticide,
        dose_per_hectare: 2.1,
        price_per_liter: 7,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][4],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][5],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[0].crop_id).split('.')[1]
      ][6],
    ]),
  },
  {
    id: 103,
    program_id: 4,
    sowing_id: variety1ExecutedSowings[1].id,
    ideal_date: getCustomDate(23),
    applied_date: getCustomDate(27),
    type: 'disease',
    spraying_order: 1,
    main_disease_id: 2,
    secondary_disease_id: 3,
    products: [
      {
        id: catalogFungicides[4].pesticide_id,
        name: catalogFungicides[4].pesticide,
        dose_per_hectare: 3,
        price_per_liter: 18,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[1].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[1].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[1].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[1].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[1].crop_id).split('.')[1]
      ][4],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[1].crop_id).split('.')[1]
      ][5],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[1].crop_id).split('.')[1]
      ][6],
    ]),
  },
  {
    id: 104,
    program_id: 7,
    sowing_id: variety2ExecutedSowings[1].id,
    ideal_date: getCustomDate(-7),
    applied_date: getCustomDate(-3),
    type: 'disease',
    spraying_order: 1,
    main_disease_id: 5,
    secondary_disease_id: 6,
    products: [
      {
        id: catalogFungicides[4].pesticide_id,
        name: catalogFungicides[4].pesticide,
        dose_per_hectare: 7,
        price_per_liter: 11,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety2ExecutedSowings[1].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety2ExecutedSowings[1].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety2ExecutedSowings[1].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety2ExecutedSowings[1].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety2ExecutedSowings[1].crop_id).split('.')[1]
      ][4],
      seasonDiseases[
        getCropNameById(variety2ExecutedSowings[1].crop_id).split('.')[1]
      ][5],
      seasonDiseases[
        getCropNameById(variety2ExecutedSowings[1].crop_id).split('.')[1]
      ][6],
    ]),
  },
  {
    id: 105,
    program_id: 8,
    sowing_id: variety8ExecutedSowings[0].id,
    ideal_date: getCustomDate(-5),
    applied_date: getCustomDate(-3),
    type: 'disease',
    spraying_order: 1,
    main_disease_id: 4,
    secondary_disease_id: 1,
    products: [
      {
        id: catalogFungicides[7].pesticide_id,
        name: catalogFungicides[7].pesticide,
        dose_per_hectare: 3.3,
        price_per_liter: 5,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][4],
    ]),
  },
  {
    id: 106,
    program_id: 8,
    sowing_id: variety8ExecutedSowings[0].id,
    ideal_date: getCustomDate(-1),
    applied_date: getCustomDate(-1),
    type: 'disease',
    spraying_order: 2,
    main_disease_id: 4,
    secondary_disease_id: 1,
    products: [
      {
        id: catalogFungicides[8].pesticide_id,
        name: catalogFungicides[8].pesticide,
        dose_per_hectare: 8.9,
        price_per_liter: 3.9,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety8ExecutedSowings[0].crop_id).split('.')[1]
      ][4],
    ]),
  },
  // without programs
  {
    id: 1000,
    program_id: null,
    sowing_id: variety1ExecutedSowings[2].id,
    ideal_date: getCustomDate(205),
    applied_date: null,
    type: 'disease',
    spraying_order: 1,
    main_disease_id: 1,
    secondary_disease_id: null,
    products: [
      {
        id: catalogFungicides[0].pesticide_id,
        name: catalogFungicides[0].pesticide,
        dose_per_hectare: 7.54,
        price_per_liter: 37,
      },
      {
        id: catalogFungicides[1].pesticide_id,
        name: catalogFungicides[1].pesticide,
        dose_per_hectare: 0.5,
        price_per_liter: 9,
      },
      {
        id: catalogFungicides[2].pesticide_id,
        name: catalogFungicides[2].pesticide,
        dose_per_hectare: 3.84,
        price_per_liter: 13,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][4],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][5],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][6],
    ]),
  },
  {
    id: 1001,
    program_id: null,
    sowing_id: variety1ExecutedSowings[2].id,
    ideal_date: getCustomDate(215),
    applied_date: null,
    type: 'disease',
    spraying_order: 2,
    main_disease_id: 1,
    secondary_disease_id: null,
    products: [
      {
        id: catalogFungicides[4].pesticide_id,
        name: catalogFungicides[4].pesticide,
        dose_per_hectare: 5.67,
        price_per_liter: 34,
      },
      {
        id: catalogFungicides[5].pesticide_id,
        name: catalogFungicides[5].pesticide,
        dose_per_hectare: 4.2,
        price_per_liter: 1,
      },
    ],
    efficacies: getRandomEfficacies([
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][0],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][1],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][2],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][3],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][4],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][5],
      seasonDiseases[
        getCropNameById(variety1ExecutedSowings[2].crop_id).split('.')[1]
      ][6],
    ]),
  },
]

const getExecutedSprayings = () => [...executedSprayings]
const getPlanningSprayings = () => [...planningSprayings]

const getAllSprayings = () => [...executedSprayings, ...planningSprayings]

export {
  getAllSprayings,
  executedSprayings,
  getExecutedSprayings,
  planningSprayings,
  getPlanningSprayings,
}
