import { seedClasses, seederSystems } from '@/services/__mocks__/seederSeeds.js'
import { fields } from '@/modules/plot/mock/fieldSeeds.js'
import { varieties } from '@/pages/cultivares/mock/varietySeeds.js'
import { farms } from '@/services/__mocks__/farmSeeds'
import { getCustomDate } from '@/utils/time'
import { getCropNameById, SOYBEAN_ID } from '@/utils/crops'

const planningSowings = [
  {
    id: 100,
    farm_id: farms[0].id,
    field_id: fields[0].id,
    variety_id: varieties[0].id,
    variety_name: varieties[0].name,
    crop_id: varieties[0].crop.id,
    crop_message_key: varieties[0].crop.message_key,
    cycle: 120,
    sowing_status: 'planning',
    harvest_status: null,
    growth_stage: null,
    estimated_date: getCustomDate(25),
    date: null,
    emergence_date: null,
    harvested_at: null,
    area: null,
    seed_class_id: null,
    germinative_power: null,
    seed_vigor: null,
    seeder_system_id: null,
    line_spacing: null,
    population_lines: null,
    sown_quantity: null,
    operating_speed: null,
    origin: null,
    seed_treatment: null,
    inoculant: null,
    harvested: null,
    harvest_quantity: null,
  },
  {
    id: 101,
    farm_id: farms[0].id,
    field_id: fields[0].id,
    variety_id: varieties[0].id,
    variety_name: varieties[0].name,
    crop_id: varieties[0].crop.id,
    crop_message_key: varieties[0].crop.message_key,
    cycle: 125,
    sowing_status: 'planning',
    harvest_status: null,
    growth_stage: null,
    estimated_date: getCustomDate(30),
    date: null,
    emergence_date: null,
    harvested_at: null,
    area: null,
    seed_class_id: null,
    germinative_power: null,
    seed_vigor: null,
    seeder_system_id: null,
    line_spacing: null,
    population_lines: null,
    sown_quantity: null,
    operating_speed: null,
    origin: null,
    seed_treatment: null,
    inoculant: null,
    harvested: null,
    harvest_quantity: null,
  },
  {
    id: 102,
    farm_id: farms[0].id,
    field_id: fields[0].id,
    variety_id: varieties[0].id,
    variety_name: varieties[0].name,
    crop_id: varieties[0].crop.id,
    crop_message_key: varieties[0].crop.message_key,
    cycle: 130,
    sowing_status: 'planning',
    harvest_status: null,
    growth_stage: null,
    estimated_date: getCustomDate(200),
    date: null,
    emergence_date: null,
    harvested_at: null,
    area: null,
    seed_class_id: null,
    germinative_power: null,
    seed_vigor: null,
    seeder_system_id: null,
    line_spacing: null,
    population_lines: null,
    sown_quantity: null,
    operating_speed: null,
    origin: null,
    seed_treatment: null,
    inoculant: null,
    harvested: null,
    harvest_quantity: null,
  },
  {
    id: 103,
    farm_id: farms[0].id,
    field_id: fields[1].id,
    variety_id: varieties[6].id,
    variety_name: varieties[6].name,
    crop_id: varieties[6].crop.id,
    crop_message_key: varieties[6].crop.message_key,
    cycle: 135,
    sowing_status: 'planning',
    harvest_status: null,
    growth_stage: null,
    estimated_date: getCustomDate(15),
    date: null,
    emergence_date: null,
    harvested_at: null,
    area: null,
    seed_class_id: null,
    germinative_power: null,
    seed_vigor: null,
    seeder_system_id: null,
    line_spacing: null,
    population_lines: null,
    sown_quantity: null,
    operating_speed: null,
    origin: null,
    seed_treatment: null,
    inoculant: null,
    harvested: null,
    harvest_quantity: null,
  },
  {
    id: 104,
    farm_id: farms[1].id,
    field_id: fields[5].id,
    variety_id: varieties[0].id,
    variety_name: varieties[0].name,
    crop_id: varieties[0].crop.id,
    crop_message_key: varieties[0].crop.message_key,
    cycle: 135,
    sowing_status: 'planning',
    harvest_status: null,
    growth_stage: null,
    estimated_date: getCustomDate(300),
    date: null,
    emergence_date: null,
    harvested_at: null,
    area: fields[1].area / 2,
    seed_class_id: seedClasses[0].id,
    germinative_power: Math.floor(Math.random() * 100),
    seed_vigor: Math.floor(Math.random() * 100),
    seeder_system_id: seederSystems[0].id,
    line_spacing: Math.floor(Math.random() * 100),
    population_lines: 20,
    sown_quantity: 47,
    operating_speed: 15,
    origin: 'own',
    seed_treatment: 'yes',
    inoculant: 'no',
    harvested: 'no',
    harvest_quantity: null,
  },
]

const PSTWS1 = JSON.parse(JSON.stringify(planningSowings[0]))
PSTWS1.estimated_date = getCustomDate(26)
PSTWS1.sowing_status = 'executed'
PSTWS1.harvest_status = 'scheduled'
PSTWS1.area = fields[0].area / 10
PSTWS1.seed_class_id = seedClasses[0].id
PSTWS1.germinative_power = Math.floor(Math.random() * 100)
PSTWS1.seed_vigor = Math.floor(Math.random() * 100)
PSTWS1.seeder_system_id = seederSystems[0].id
PSTWS1.line_spacing = Math.floor(Math.random() * 100)
PSTWS1.population_lines = Math.floor(Math.random() * 20)
PSTWS1.sown_quantity = Math.floor(Math.random() * 100)
PSTWS1.operating_speed = Math.floor(Math.random() * 20)
PSTWS1.origin = 'own'
PSTWS1.seed_treatment = 'yes'
PSTWS1.inoculant = 'no'
PSTWS1.harvested = 'no'
PSTWS1.harvest_quantity = null

const PSTWS2 = JSON.parse(JSON.stringify(planningSowings[2]))
PSTWS1.estimated_date = getCustomDate(203)
PSTWS2.sowing_status = 'executed'
PSTWS2.harvest_status = 'scheduled'
PSTWS2.area = fields[0].area / 9
PSTWS1.seed_class_id = seedClasses[1].id
PSTWS1.germinative_power = Math.floor(Math.random() * 100)
PSTWS1.seed_vigor = Math.floor(Math.random() * 100)
PSTWS1.seeder_system_id = seederSystems[1].id
PSTWS1.line_spacing = Math.floor(Math.random() * 100)
PSTWS1.population_lines = Math.floor(Math.random() * 20)
PSTWS1.sown_quantity = Math.floor(Math.random() * 100)
PSTWS1.operating_speed = Math.floor(Math.random() * 20)
PSTWS1.origin = 'bought'
PSTWS1.seed_treatment = 'no'
PSTWS1.inoculant = 'yes'
PSTWS1.harvested = 'no'
PSTWS1.harvest_quantity = null

const planningSowingsThatWereExecuted = [PSTWS1, PSTWS2]
const PSTWSIds = planningSowingsThatWereExecuted.map((sowing) => sowing.id)

const getPlanningSowingsAndPlanningSowingsThatWereExecutedWithoutDuplicated =
  () => {
    return planningSowings.reduce((acc, sowing) => {
      let sowingToAdd = sowing
      if (PSTWSIds.includes(sowing.id)) {
        sowingToAdd = planningSowingsThatWereExecuted.find(
          (PSTWE) => PSTWE.id == sowing.id
        )
      }
      acc.push(sowingToAdd)
      return acc
    }, [])
  }

const getPlanningSowingsWithoutDuplicated = () => {
  return planningSowings.reduce((acc, sowing) => {
    if (!PSTWSIds.includes(sowing.id)) {
      acc.push(sowing)
    }
    return acc
  }, [])
}

const executedSowings = [
  {
    id: 1,
    farm_id: farms[0].id,
    field_id: fields[0].id,
    variety_id: varieties[0].id,
    variety_name: varieties[0].name,
    crop_id: varieties[0].crop.id,
    crop_message_key: varieties[0].crop.message_key,
    cycle: 100,
    sowing_status: 'executed',
    harvest_status: 'harvested',
    growth_stage: 'full',
    estimated_date: getCustomDate(-1),
    date: getCustomDate(0),
    emergence_date: getCustomDate(50),
    harvested_at: getCustomDate(102),
    area: fields[0].area / 10,
    seed_class_id: seedClasses[0].id,
    germinative_power: Math.floor(Math.random() * 100),
    seed_vigor: Math.floor(Math.random() * 100),
    seeder_system_id: seederSystems[0].id,
    line_spacing: Math.floor(Math.random() * 100),
    population_lines: 16,
    sown_quantity: 30,
    operating_speed: 12,
    origin: 'bought',
    seed_treatment: 'yes',
    inoculant: 'yes',
    harvested: 'yes',
    harvest_quantity: 20,
  },
  {
    id: 2,
    farm_id: farms[0].id,
    field_id: fields[0].id,
    variety_id: varieties[1].id,
    variety_name: varieties[1].name,
    crop_id: varieties[1].crop.id,
    crop_message_key: varieties[1].crop.message_key,
    cycle: 105,
    sowing_status: 'executed',
    harvest_status: 'ready_to_harvest',
    growth_stage: 'full',
    estimated_date: getCustomDate(-7),
    date: getCustomDate(-7),
    emergence_date: getCustomDate(55),
    harvested_at: null,
    area: fields[0].area / 10,
    seed_class_id: seedClasses[1].id,
    germinative_power: Math.floor(Math.random() * 100),
    seed_vigor: Math.floor(Math.random() * 100),
    seeder_system_id: seederSystems[1].id,
    line_spacing: Math.floor(Math.random() * 100),
    population_lines: 20,
    sown_quantity: 40,
    operating_speed: 20,
    origin: 'own',
    seed_treatment: 'no',
    inoculant: 'no',
    harvested: 'no',
    harvest_quantity: null,
  },
  {
    id: 3,
    farm_id: farms[0].id,
    field_id: fields[0].id,
    variety_id: varieties[2].id,
    variety_name: varieties[2].name,
    crop_id: varieties[2].crop.id,
    crop_message_key: varieties[2].crop.message_key,
    cycle: 110,
    sowing_status: 'executed',
    harvest_status: 'ongoing',
    growth_stage: 'v4',
    estimated_date: getCustomDate(-14),
    date: getCustomDate(-16),
    emergence_date: getCustomDate(55),
    harvested_at: null,
    area: fields[0].area / 10,
    seed_class_id: seedClasses[2].id,
    germinative_power: Math.floor(Math.random() * 100),
    seed_vigor: Math.floor(Math.random() * 100),
    seeder_system_id: seederSystems[2].id,
    line_spacing: Math.floor(Math.random() * 100),
    population_lines: 12,
    sown_quantity: 50,
    operating_speed: 18,
    origin: 'bought',
    seed_treatment: 'no',
    inoculant: 'yes',
    harvested: 'no',
    harvest_quantity: null,
  },
  {
    id: 4,
    farm_id: farms[0].id,
    field_id: fields[0].id,
    variety_id: varieties[1].id,
    variety_name: varieties[1].name,
    crop_id: varieties[1].crop.id,
    crop_message_key: varieties[1].crop.message_key,
    cycle: 130,
    sowing_status: 'executed',
    harvest_status: 'scheduled',
    growth_stage: null,
    estimated_date: getCustomDate(365),
    date: null,
    emergence_date: null,
    harvested_at: null,
    area: fields[0].area / 10,
    seed_class_id: seedClasses[0].id,
    germinative_power: Math.floor(Math.random() * 100),
    seed_vigor: Math.floor(Math.random() * 100),
    seeder_system_id: seederSystems[0].id,
    line_spacing: Math.floor(Math.random() * 100),
    population_lines: 12,
    sown_quantity: 43,
    operating_speed: 18,
    origin: 'own',
    seed_treatment: 'yes',
    inoculant: 'no',
    harvested: 'no',
    harvest_quantity: null,
  },
  ...planningSowingsThatWereExecuted,
  {
    id: 5,
    farm_id: farms[0].id,
    field_id: fields[1].id,
    variety_id: varieties[7].id,
    variety_name: varieties[7].name,
    crop_id: varieties[7].crop.id,
    crop_message_key: varieties[7].crop.message_key,
    cycle: 120,
    sowing_status: 'executed',
    harvest_status: 'ongoing',
    growth_stage: '6',
    estimated_date: getCustomDate(25),
    date: getCustomDate(24),
    emergence_date: getCustomDate(63),
    harvested_at: null,
    area: fields[1].area / 2,
    seed_class_id: seedClasses[2].id,
    germinative_power: Math.floor(Math.random() * 100),
    seed_vigor: Math.floor(Math.random() * 100),
    seeder_system_id: seederSystems[2].id,
    line_spacing: Math.floor(Math.random() * 100),
    population_lines: 18,
    sown_quantity: fields[1].area / 2,
    operating_speed: 15,
    origin: 'bought',
    seed_treatment: 'yes',
    inoculant: 'no',
    harvested: 'no',
    harvest_quantity: null,
  },
]

const sowingsForDefaultPrograms = [
  {
    id: 1000,
    date: getCustomDate(12),
    crop_id: SOYBEAN_ID,
    crop_message_key: getCropNameById(SOYBEAN_ID),
    variety_name: 'Todas',
  },
  {
    id: 1001,
    date: getCustomDate(20),
    crop_id: SOYBEAN_ID,
    crop_message_key: getCropNameById(SOYBEAN_ID),
    variety_name: 'Todas',
  },
]

const getExecutedSowings = () => [...executedSowings]

const getPlanningAndExecutedSowings = () => [
  ...getPlanningSowingsWithoutDuplicated(),
  ...getExecutedSowings(),
]

export {
  sowingsForDefaultPrograms,
  executedSowings,
  getExecutedSowings,
  getPlanningAndExecutedSowings,
  getPlanningSowingsAndPlanningSowingsThatWereExecutedWithoutDuplicated,
  getPlanningSowingsWithoutDuplicated,
  planningSowings,
  PSTWS1,
  PSTWS2,
}
