import {
  cropIdQuery,
  farmIdQuery,
  seasonCropIdQuery,
  periodQuery,
  queryBuilder,
} from '@/utils/apiQueryBuilder'
import http from '@/utils/http'
import auth from '@/modules/auth/services/authService'
import permissions from '@/modules/auth/services/permissionService'
import accountPending from '../modules/auth/services/accountConfirmService'

const menu = {
  usuario: () => http.get('/menu/dados-usuario'),
  safras: () => http.get('/menu/safras'),
  contas: () => http.get('/menu/contas'),
  alterarSafra: (seasonId) => http.put('/menu/alterar-safra', { seasonId }),
  alterarConta: (id) => http.put('/menu/alterar-conta', { id: id }),
  paisConta: () => http.get('/menu/pais-conta'),
}

const fazendas = {
  getFarms: (sort = null) => http.get('/farms' + (sort ? `?sort=${sort}` : '')),
  getFarm: (id) => http.get(`/farms/${id}`),

  all: () => http.get('/fazendas'),
  get: (id) => http.get(`/fazendas/${id}`),

  allWithCultivares: () => http.get('/fazendas?include=cultivares'),
  allOfThisCultivar: (semente_id) =>
    http.get(`/fazendas?include=cultivar&semente_id=${semente_id}`),

  // FIXME: este endpoint foi uma gambiarra para não mexer no endpoint acima que é usado em diversos lugares
  getWithInclude: (id, include) =>
    http.get(`/fazendas/${id}?include=${include.join()}`),

  create: (fazenda) => http.post('/fazendas', fazenda),
  update: (fazenda) => http.put(`/fazendas/${fazenda.id}`, fazenda),
  delete: (id) => http.delete(`/fazendas/${id}`),
  clima: (id) => http.get(`/fazendas/${id}/clima`),
  precipitation: (farmId) => http.get(`/farms/${farmId}/weather`),
  precipitationOld: (farmId) => http.get(`/fazendas/${farmId}/precipitation`),
  savePrecipitation: (farmId, data) =>
    http.post(`/fazendas/${farmId}/precipitation`, { observed: data }),
  stats: (id, startPeriod = null, endPeriod = null) =>
    http.get(
      `/fazendas/${id}/stats` +
        (startPeriod ? `?start_period=${startPeriod}` : '') +
        (endPeriod ? `&end_period=${endPeriod}` : '')
    ),
  bagPrices: (farmId, bagPrices) =>
    http.put(`/farms/${farmId}/bag-prices`, bagPrices),
  enableDisable: (farmId) => http.put(`/farms/${farmId}/enable-disable`),
}

const programas = {
  findByCultivar: (cultivar_id, startPeriod = null, endPeriod = null) =>
    http.get(
      `/programas?cultivar_id=${cultivar_id}` +
        (startPeriod ? `&start_period=${startPeriod}` : '') +
        (endPeriod ? `&end_period=${endPeriod}` : '')
    ),
  findBySemeadura: (semeadura_id) =>
    http.get(`/programas?semeadura_id=${semeadura_id}`), // TODO

  create: (programa) => http.post('/programas', programa),
  update: (programa) => http.put(`/programas/${programa.id}`, programa),
  delete: (id) => http.delete(`/programas/${id}`),
  clonarParaCultivares: (id, cultivaresId, farmId) =>
    http.post(`/programas/${id}/clonar`, {
      cultivares: cultivaresId,
      farm_id: farmId,
    }),
  importToSemeadura: (programaId, semeaduraId) =>
    http.post(`/programas/${programaId}/importar`, {
      semeadura_id: semeaduraId,
    }),

  sugerir: (programa, include, aplicacao, doencaPrioritariaId) =>
    http.get(
      `/programas/${
        programa.id
      }/planejamentos/sugerir?include=${include.join()}&aplicacao=${aplicacao}` +
        (doencaPrioritariaId
          ? `&doenca_prioritaria_id=${doencaPrioritariaId}`
          : '')
    ),

  planejamentos: {
    create: (planejamento) =>
      http.post(
        `/programas/${planejamento.programa_id}/planejamentos`,
        planejamento
      ),
    update: (planejamento) =>
      http.put(
        `/programas/${planejamento.programa_id}/planejamentos/${planejamento.id}`,
        planejamento
      ),
    delete: (planejamento) =>
      http.delete(
        `/programas/${planejamento.programa_id}/planejamentos/${planejamento.id}`
      ),
  },

  execucoes: {
    applySpray: ({ programId, ordinal, applied_at }) =>
      http.post(`/programs/${programId}/sprayings/${ordinal}/apply`, {
        applied_at,
      }),
    unapplySpray: ({ programId, ordinal }) =>
      http.post(`/programs/${programId}/sprayings/${ordinal}/unapply`),
    create: (execucao) =>
      http.post(`/programas/${execucao.programa_id}/execucoes`, execucao), // TODO
    update: (execucao) =>
      http.put(
        `/programas/${execucao.programa_id}/execucoes/${execucao.id}`,
        execucao
      ),
    delete: (execucao) =>
      http.delete(
        `/programas/${execucao.programa_id}/execucoes/${execucao.id}`
      ),
  },
}

const defensivos = {
  findByFazenda: (farmId, crop_id, program_id) => {
    return http.get(
      `/defensives?farm_id=${farmId}&crop_id=${crop_id}&program_id=${program_id}`
    )
  },
  findDefensivesLastValuesByAccount: () => http.get(`/defensives/last-values`),
}

const adjuvantes = {
  all: () => http.get('/adjuvantes'),
}

const cultivares = {
  get: (id, seasonCropId = null) =>
    http.get(
      `/cultivares?fazenda_id=${id}` +
        (seasonCropId ? `&season_crop=${seasonCropId}` : '')
    ),
  delete: ({ id, farmId }) =>
    http.delete(`/cultivares/${id}/fazenda/${farmId}`),
  solicitacao: (form, descricao) =>
    http.post(`/cultivares/solicitacao?form=${form}&descricao=${descricao}`),
  clonar: (cultivar_id, fazendasId) =>
    http.post(`/cultivares/${cultivar_id}/clonar`, { fazendas: fazendasId }),
}

const sementes = {
  getSeeds: (
    farmId,
    cropId,
    seasonCropId = null,
    startPeriod = null,
    endPeriod = null
  ) =>
    http.get(
      `/sementes/fazenda/${farmId}${queryBuilder(
        cropIdQuery(cropId),
        seasonCropIdQuery(seasonCropId),
        periodQuery(startPeriod, endPeriod)
      )}`
    ),
  getSeedsWithCultivares: (farmId, cropId, seasonCropId = null) =>
    http.get(
      `/sementes/fazenda/${farmId}${queryBuilder(
        cropIdQuery(cropId),
        seasonCropIdQuery(seasonCropId)
      )}&include=cultivares`
    ),
  getDataEmergencia: (data) => http.get(`/sementes/data-emergencia/${data}`),
}

const seedClasses = {
  getSeedClasses: () => http.get(`/seed-class`),
}

const seederSystems = {
  getSeederSystems: () => http.get(`/seeder-system`),
}

const semeaduras = {
  findByFazenda: (fazenda_id, include, startPeriod = null, endPeriod = null) =>
    http.get(
      `/semeaduras?fazenda_id=${fazenda_id}` +
        (include ? `&include=${include.join()}` : '') +
        (startPeriod ? `&start_period=${startPeriod}` : '') +
        (endPeriod ? `&end_period=${endPeriod}` : '')
    ),
  delete: (id) => http.delete(`/semeaduras/${id}`),
  create: (semeadura) => http.post(`/semeaduras`, semeadura),
  update: (semeadura) => http.put(`/semeaduras/${semeadura.id}`, semeadura),

  colheita: {
    create: (colheita) =>
      http.post(`/semeaduras/${colheita.semeadura_id}/colheita`, colheita),
    update: (colheita) =>
      http.put(`/semeaduras/${colheita.semeadura_id}/colheita`, colheita),
    delete: (id) => http.delete(`/semeaduras/${id}/colheita`),
    sowingsReadyToHarvest: (farmId) =>
      http.get(`/farms/${farmId}/sowingsReadyToHarvest`),
    createManyHarvests: (harvests) => http.post(`/harvests/many`, harvests),
  },

  estadios: {
    create: (estadio) =>
      http.post(`/semeaduras/${estadio.semeadura_id}/estadio`, estadio),
    getList: (programId) => http.get(`/programs/${programId}/growth-stages`),
  },
}

const usuarios = {
  alterarIdioma: (sigla) => http.post(`/usuarios/idioma/${sigla}`),
}

const estados = {
  get: (countryId) => http.get(`/estados/${countryId}`),
}

const municipios = {
  get: (stateId) => http.get(`/municipios/${stateId}`),
}

const irrigacoes = {
  get: (semeadura) => http.get(`/irrigacoes?semeadura_id=${semeadura.id}`),
  create: (irrigacao) => http.post(`/irrigacoes`, irrigacao),
  update: (irrigacao) => http.put(`/irrigacoes/${irrigacao.id}`, irrigacao),
  delete: (id) => http.delete(`/irrigacoes/${id}`),
}

const talhoes = {
  get: (id, sort = null) =>
    http.get(`/talhoes/${id}` + (sort ? `?sort=${sort}` : '')),
  delete: (id) => http.delete(`/talhoes/${id}`),
  create: (talhao) => http.post('/talhoes', talhao),
  createAll: (data) => http.post('/plots/many', data),
  update: (talhao) => http.put(`/talhoes/${talhao.id}`, talhao),
  areaRestante: (talhao, farmId) =>
    http.get(`/talhoes/${talhao.id}/fazenda/${farmId}`),
  getByAccount: () => http.get('/accounts/plots-area'),
  enableDisable: (plotId) => http.put(`/plots/${plotId}/enable-disable`),
  getAllByAccount: (accountId) => http.get(`/accounts/${accountId}/allPlots`),

  updateContractedArea: (payload) => http.post(`/plots/manager-plot`, payload),
}

const defensiveOptions = {
  get: (data, seasonCropId) => {
    const addMainDefensiveId = data.mainDefensiveId
      ? `&main_defensive_id=${data.mainDefensiveId}`
      : ''

    const targets = () => {
      const addPrimaryTarget = data.primaryTarget
        ? `&major_disease_id=${data.primaryTarget}`
        : ''
      const addSecondaryTarget = data.secondaryTarget
        ? `&second_major_disease_id=${data.secondaryTarget}`
        : ''
      return data.primaryTarget === data.secondaryTarget
        ? `${addPrimaryTarget}`
        : `${addPrimaryTarget}${addSecondaryTarget}`
    }

    const seasonCrop = seasonCropId ? `&season_crop=${seasonCropId}` : ''

    return http.post(
      `/defensives/suggest?spraying_number=${data.sprayingNumber}&country_id=${
        data.countryId
      }&crop_id=${data.cropId}${targets()}${addMainDefensiveId}${seasonCrop}`
    )
  },
}

const ndvi = {
  searchImages: (data) => http.post('/ndvi', data),
}

const integrations = {
  fetchIntegrations: () => http.get('/integrations'),
  disableFieldview: () => http.delete('/integrations/fieldview'),
  fieldview: (data) => http.post('/integration/fieldview', data),
  syncFieldview: () => http.get('/integration/sync/fieldview'),
}

const crops = {
  all: () => http.get('/crops'),
}

const diseases = {
  getBySeasonCrop: (seasonCrop) =>
    http.get(`/diseases?seasonCrop=${seasonCrop}`),
}

const prescription = {
  exportPrescription: (id) =>
    http.get(`/prescription/${id}/export`, { responseType: 'blob' }),
}

const quotations = {
  getAllPhysicalMarket: (cropId) => http.get(`/markets?crop_id=${cropId}`),
  getAllOrdinaces: () => http.get('/market-indexes'),
  getDollarQuotation: () => http.get('/dollar-quotation'),
}

const seasonsCrops = {
  getSeasonsCrops: (farmId = null) =>
    http.get(`/season-crops${queryBuilder(farmIdQuery(farmId))}`),
}

const seasonAccount = {
  get: () => http.get('/season-account'),
}

const varieties = {
  create: (variety) => http.post(`/varieties`, variety),
}

const users = {
  all: () => http.get('/users'),
  create: (user) => http.post('/users', user),
  update: (user) => http.put(`/users/${user.id}`, user),
  delete: (userId) => http.delete(`/users/${userId}`),
  active: (userId) => http.put(`/users/${userId}/active`),

  profiles: {
    get: () => http.get('/profiles'),
  },
}

const notes = {
  stats: (id, startPeriod = null, endPeriod = null) =>
    http.get(
      `/fazendas/${id}/stats${queryBuilder(
        periodQuery(startPeriod, endPeriod)
      )}`
    ),
  all: (farmId, filter = []) =>
    http.get(`/farms/${farmId}/fields-notes` + (filter.length ? filter : '')),
  create: (farmId, note) => http.post(`/farms/${farmId}/fields-notes`, note),
  update: (farmId, note, noteId) =>
    http.post(`/farms/${farmId}/fields-notes/${noteId}`, note),
  delete: (noteId) => http.delete(`/fields-notes/${noteId}`),

  categories: {
    get: () => http.get('/notes-categories'),
  },
}

const jobTitles = {
  get: () => http.get('/job-titles'),
  set: (user_id, job_title_id) =>
    http.post('/job-titles', { user_id, job_title_id }),
}

const catalog = {
  getFilters: (category, crop, location, query) => {
    const country_id = location.country_id
    const state_id = location.state_id
    const city_id = location.city_id

    const queryValue = query ? `&${query}` : ''
    return http.get(
      `/catalogs/${category}/filters?crop=${crop}&country=${country_id}&state_id=${state_id}&city=${city_id}${queryValue}`
    )
  },
  get: (category, crop, location, query, offset) => {
    const country_id = location.country_id
    const state_id = location.state_id
    const city_id = location.city_id
    return http.get(
      `/catalogs?category=${category}&crop=${crop}&country=${country_id}&state_id=${state_id}&city=${city_id}&${query}&limit=50&offset=${offset}`
    )
  },
}

const plans = {
  getAll: () => http.get('/menu/plans'),
}

const freemiumManage = {
  get: () => http.get('/freemium'),
  set: () => http.post('/freemium/consume'),
}

export default {
  auth,
  permissions,
  menu: menu,
  fazendas: fazendas,
  programas: programas,
  cultivares: cultivares,
  semeaduras: semeaduras,
  defensivos: defensivos,
  adjuvantes: adjuvantes,
  usuarios: usuarios,
  estados: estados,
  municipios: municipios,
  talhoes: talhoes,
  sementes: sementes,
  seedClasses: seedClasses,
  seederSystems: seederSystems,
  irrigacoes: irrigacoes,
  ndvi,
  integrations,
  defensiveOptions,
  crops,
  diseases,
  prescription,
  quotations,
  seasonsCrops,
  seasonAccount,
  varieties,
  users,
  notes,
  jobTitles,
  catalog,
  plans,
  freemiumManage,
  accountPending,
}
