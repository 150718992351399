function initialState() {
  return {
    user: null,
    users: null,
    language: null,
    country: null,
  }
}

import api from '@/services/api'
import { BRAZIL, UNITED_STATES } from '@/utils/countries'

export default {
  namespaced: true,

  state: {
    initialState: initialState,
    user: initialState.user,
    users: initialState.users,
    country: initialState.country,
  },

  getters: {
    currentUser(state) {
      return state.user
    },
    users(state) {
      return state.users
    },
    areaExceededAccount(state) {
      return state?.user?.plan?.priority_level == 4 && state.user?.used_area
    },
    getCountry(state) {
      return state.country
    },
    isBrazil(state) {
      return state.country == BRAZIL
    },
    isUSA(state) {
      return state.country == UNITED_STATES
    },
    isAdmin(state) {
      return state.user?.is_admin
    },
    isFreemiumPlan(state) {
      return (
        state?.user?.plan?.name == 'Freemium' || state?.user?.plan?.id === 5
      )
    },
    isPlanLevelOne(state) {
      return state?.user?.plan?.priority_level == 1
    },
    isPlanLevelTwo(state) {
      return state?.user?.plan?.priority_level == 2
    },
    isPlanLevelThree(state) {
      return state?.user?.plan?.priority_level == 3
    },
    isPlanLevelFour(state) {
      return state?.user?.plan?.priority_level == 4
    },
    contractedPlanLevel(state) {
      return state?.user?.plan.priority_level
    },
  },

  mutations: {
    SET_USERS(state, users) {
      state.users = users
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_COUNTRY(state, country) {
      state.country = country
    },
  },

  actions: {
    async fetchUserData({ commit }, i18n) {
      try {
        const { data } = await api.menu.usuario()
        commit('SET_USER', data)
        if (!localStorage.getItem('language')) {
          i18n.locale = data.language
          localStorage.setItem('language', data.language)
        }
        commit('SET_COUNTRY', data.account.country_id)
      } catch (e) {
        throw new Error(e)
      }
    },
    async fetchUsers({ commit }) {
      try {
        const { data } = await api.users.all()
        commit('SET_USERS', data)
      } catch (e) {
        throw new Error(e)
      }
    },
    async deleteUser({ dispatch }, userId) {
      try {
        await api.users.delete(userId)
        dispatch('fetchUsers')
      } catch (e) {
        throw new Error(e)
      }
    },
    async changeUserStatus({ dispatch }, userId) {
      try {
        await api.users.active(userId)
        dispatch('fetchUsers')
      } catch (e) {
        throw new Error(e)
      }
    },
    resetStore({ commit }) {
      commit('SET_USERS', initialState().users)
      commit('SET_USER', initialState().user)
      commit('SET_COUNTRY', initialState().country)
    },
  },
}
