const seederSystems = [
  {
    id: 1,
    name: 'Perforated disk',
    messageKey: 'seeder_system.perforated_disk',
  },
  {
    id: 2,
    name: 'Endless thread',
    messageKey: 'seeder_system.endless_thread',
  },
  {
    id: 3,
    name: 'Pinching fingers',
    messageKey: 'seeder_system.pinching_fingers',
  },
  {
    id: 4,
    name: 'Perforated belt',
    messageKey: 'seeder_system.perforated_belt',
  },
  {
    id: 5,
    name: 'Fluted cylinder',
    messageKey: 'seeder_system.fluted_cylinder',
  },
  {
    id: 6,
    name: 'Pneumatic seeder',
    messageKey: 'seeder_system.pneumatic_seeder',
  },
  {
    id: 7,
    name: 'Others',
    messageKey: 'seeder_system.others',
  },
]

const seedClasses = [
  {
    id: 1,
    name: 'Certified',
    messageKey: 'seed_class.certified',
  },
  {
    id: 2,
    name: 'Inspected',
    messageKey: 'seed_class.inspected',
  },
  {
    id: 3,
    name: 'Selected',
    messageKey: 'seed_class.selected',
  },
]

export { seedClasses, seederSystems }
