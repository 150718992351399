import {
  farmClima,
  farmPrecipitation,
  farmWeather,
  farms,
} from '@/services/__mocks__/farmSeeds.js'
import { mockFlag } from '@/utils/mock'
import { APIFailureWrapper } from '@/utils/mock'

const farmRoutes = [
  mockFlag(
    {
      method: 'get',
      url: '/farms',
      result: () => {
        const filteredFarms = farms.filter((farm) => farm.enabled)
        return APIFailureWrapper({
          content: filteredFarms,
          errorMessage: 'Erro ao buscar fazendas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/farms/:id/weather',
      result: () => {
        // se precisar no futuro, filtra um clima específico pra fazenda
        return APIFailureWrapper({
          content: farmWeather,
          errorMessage: 'Erro ao buscar fazendas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/fazendas/:id/clima',
      result: () => {
        // se precisar no futuro, filtra um clima específico pra fazenda
        return APIFailureWrapper({
          content: farmClima,
          errorMessage: 'Erro ao buscar fazendas',
        })
      },
    },
    'on'
  ),
  mockFlag(
    {
      method: 'get',
      url: '/fazendas/:id/precipitation',
      result: () => {
        // se precisar no futuro, filtra um clima específico pra fazenda
        return APIFailureWrapper({
          content: farmPrecipitation,
          errorMessage: 'Erro ao buscar fazendas',
        })
      },
    },
    'on'
  ),
]
export { farmRoutes }
