import i18n from '@/language'
import { catalogFungicides } from '@/modules/catalog/mock/catalogSeeds'
import { BRAZIL } from '@/utils/countries'
import { SOYBEAN_ID } from '@/utils/crops'

const soybeanDiseases = [
  {
    id: 1,
    crop_id: 1,
    code: 'soybean_asian_soybean_rust',
  },
  {
    id: 2,
    crop_id: 1,
    code: 'soybean_anthracnosis',
  },
  {
    id: 3,
    crop_id: 1,
    code: 'soybean_target_spot',
  },
  {
    id: 4,
    crop_id: 1,
    code: 'soybean_powdery_mildew',
  },
  {
    id: 5,
    crop_id: 1,
    code: 'soybean_cercospora_blight',
  },
  {
    id: 6,
    crop_id: 1,
    code: 'soybean_septoria_leaf_spot',
  },
  {
    id: 7,
    crop_id: 1,
    code: 'soybean_white_mold',
  },
]

const wheatDiseases = [
  {
    id: 1,
    crop_id: 2,
    code: 'wheat_leaf_rust',
  },
  {
    id: 2,
    crop_id: 2,
    code: 'wheat_powdery_mildew',
  },
  {
    id: 3,
    crop_id: 2,
    code: 'wheat_yellow_leaf_spot',
  },
  {
    id: 4,
    crop_id: 2,
    code: 'wheat_fusarium_head_blight',
  },
  {
    id: 5,
    crop_id: 2,
    code: 'wheat_blast',
  },
]

const seasonDiseases = { soybean: soybeanDiseases, wheat: wheatDiseases }

const pesticides = [
  {
    id: catalogFungicides[0].pesticide_id,
    crop_id: SOYBEAN_ID,
    name: catalogFungicides[0].pesticide,
    price: Math.floor(Math.random() * 100),
    dose: Math.floor(Math.random() * 100),
    unit: 'L',
    type: 'phytosanitary_category.diseases',
    country_id: BRAZIL,
    is_white_mold: true,
    active_ingredients: catalogFungicides[0].activeIngredients.map(
      (ingredient, index) => {
        return {
          id: index,
          name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
        }
      }
    ),
    doses: {
      soybean_asian_soybean_rust: {
        target_id: seasonDiseases.soybean[0].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_anthracnosis: {
        target_id: seasonDiseases.soybean[1].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_target_spot: {
        target_id: seasonDiseases.soybean[2].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_powdery_mildew: {
        target_id: seasonDiseases.soybean[3].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_cercospora_blight: {
        target_id: seasonDiseases.soybean[4].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_septoria_leaf_spot: {
        target_id: seasonDiseases.soybean[5].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_white_mold: {
        target_id: seasonDiseases.soybean[6].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
    },
    efficacies: getRandomEfficacies([
      seasonDiseases.soybean[0],
      seasonDiseases.soybean[1],
      seasonDiseases.soybean[2],
      seasonDiseases.soybean[3],
      seasonDiseases.soybean[4],
      seasonDiseases.soybean[5],
      seasonDiseases.soybean[6],
    ]),
    mixtures: [
      {
        defensive_id: catalogFungicides[1].pesticide_id,
        defensive_name: catalogFungicides[1].pesticide,
        active_ingredients: catalogFungicides[1].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
      {
        defensive_id: catalogFungicides[2].pesticide_id,
        defensive_name: catalogFungicides[2].pesticide,
        active_ingredients: catalogFungicides[2].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
      {
        defensive_id: catalogFungicides[3].pesticide_id,
        defensive_name: catalogFungicides[3].pesticide,
        active_ingredients: catalogFungicides[3].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
    ],
  },
  {
    id: catalogFungicides[1].pesticide_id,
    crop_id: SOYBEAN_ID,
    name: catalogFungicides[1].pesticide,
    price: Math.floor(Math.random() * 100),
    dose: Math.floor(Math.random() * 100),
    unit: 'L',
    type: 'phytosanitary_category.diseases',
    country_id: BRAZIL,
    is_white_mold: true,
    active_ingredients: catalogFungicides[1].activeIngredients.map(
      (ingredient, index) => {
        return {
          id: index,
          name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
        }
      }
    ),
    doses: {
      soybean_asian_soybean_rust: {
        target_id: seasonDiseases.soybean[0].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_anthracnosis: {
        target_id: seasonDiseases.soybean[1].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_target_spot: {
        target_id: seasonDiseases.soybean[2].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_powdery_mildew: {
        target_id: seasonDiseases.soybean[3].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_cercospora_blight: {
        target_id: seasonDiseases.soybean[4].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_septoria_leaf_spot: {
        target_id: seasonDiseases.soybean[5].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_white_mold: {
        target_id: seasonDiseases.soybean[6].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
    },
    efficacies: getRandomEfficacies([
      seasonDiseases.soybean[0],
      seasonDiseases.soybean[1],
      seasonDiseases.soybean[2],
      seasonDiseases.soybean[3],
      seasonDiseases.soybean[4],
      seasonDiseases.soybean[5],
      seasonDiseases.soybean[6],
    ]),
    mixtures: [
      {
        defensive_id: catalogFungicides[2].pesticide_id,
        defensive_name: catalogFungicides[2].pesticide,
        active_ingredients: catalogFungicides[2].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
      {
        defensive_id: catalogFungicides[3].pesticide_id,
        defensive_name: catalogFungicides[3].pesticide,
        active_ingredients: catalogFungicides[3].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
    ],
  },
  {
    id: catalogFungicides[2].pesticide_id,
    crop_id: SOYBEAN_ID,
    name: catalogFungicides[2].pesticide,
    price: Math.floor(Math.random() * 100),
    dose: Math.floor(Math.random() * 100),
    unit: 'L',
    type: 'phytosanitary_category.diseases',
    country_id: BRAZIL,
    is_white_mold: true,
    active_ingredients: catalogFungicides[2].activeIngredients.map(
      (ingredient, index) => {
        return {
          id: index,
          name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
        }
      }
    ),
    doses: {
      soybean_asian_soybean_rust: {
        target_id: seasonDiseases.soybean[0].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_anthracnosis: {
        target_id: seasonDiseases.soybean[1].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_target_spot: {
        target_id: seasonDiseases.soybean[2].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_powdery_mildew: {
        target_id: seasonDiseases.soybean[3].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_cercospora_blight: {
        target_id: seasonDiseases.soybean[4].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_septoria_leaf_spot: {
        target_id: seasonDiseases.soybean[5].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_white_mold: {
        target_id: seasonDiseases.soybean[6].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
    },
    efficacies: getRandomEfficacies([
      seasonDiseases.soybean[0],
      seasonDiseases.soybean[1],
      seasonDiseases.soybean[2],
      seasonDiseases.soybean[3],
      seasonDiseases.soybean[4],
      seasonDiseases.soybean[5],
      seasonDiseases.soybean[6],
    ]),
    mixtures: [
      {
        defensive_id: catalogFungicides[0].pesticide_id,
        defensive_name: catalogFungicides[0].pesticide,
        active_ingredients: catalogFungicides[0].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
      {
        defensive_id: catalogFungicides[1].pesticide_id,
        defensive_name: catalogFungicides[1].pesticide,
        active_ingredients: catalogFungicides[1].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
      {
        defensive_id: catalogFungicides[3].pesticide_id,
        defensive_name: catalogFungicides[3].pesticide,
        active_ingredients: catalogFungicides[3].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
      {
        defensive_id: catalogFungicides[4].pesticide_id,
        defensive_name: catalogFungicides[4].pesticide,
        active_ingredients: catalogFungicides[4].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
    ],
  },
  {
    id: catalogFungicides[3].pesticide_id,
    crop_id: SOYBEAN_ID,
    name: catalogFungicides[3].pesticide,
    price: Math.floor(Math.random() * 100),
    dose: Math.floor(Math.random() * 100),
    unit: 'L',
    type: 'phytosanitary_category.diseases',
    country_id: BRAZIL,
    is_white_mold: true,
    active_ingredients: catalogFungicides[3].activeIngredients.map(
      (ingredient, index) => {
        return {
          id: index,
          name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
        }
      }
    ),
    doses: {
      soybean_asian_soybean_rust: {
        target_id: seasonDiseases.soybean[0].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_anthracnosis: {
        target_id: seasonDiseases.soybean[1].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_target_spot: {
        target_id: seasonDiseases.soybean[2].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_powdery_mildew: {
        target_id: seasonDiseases.soybean[3].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_cercospora_blight: {
        target_id: seasonDiseases.soybean[4].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_septoria_leaf_spot: {
        target_id: seasonDiseases.soybean[5].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_white_mold: {
        target_id: seasonDiseases.soybean[6].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
    },
    efficacies: getRandomEfficacies([
      seasonDiseases.soybean[0],
      seasonDiseases.soybean[1],
      seasonDiseases.soybean[2],
      seasonDiseases.soybean[3],
      seasonDiseases.soybean[4],
      seasonDiseases.soybean[5],
      seasonDiseases.soybean[6],
    ]),
    mixtures: [
      {
        defensive_id: catalogFungicides[2].pesticide_id,
        defensive_name: catalogFungicides[2].pesticide,
        active_ingredients: catalogFungicides[2].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
      {
        defensive_id: catalogFungicides[4].pesticide_id,
        defensive_name: catalogFungicides[4].pesticide,
        active_ingredients: catalogFungicides[4].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
    ],
  },
  {
    id: catalogFungicides[4].pesticide_id,
    crop_id: SOYBEAN_ID,
    name: catalogFungicides[4].pesticide,
    price: Math.floor(Math.random() * 100),
    dose: Math.floor(Math.random() * 100),
    unit: 'L',
    type: 'phytosanitary_category.diseases',
    country_id: BRAZIL,
    is_white_mold: true,
    active_ingredients: catalogFungicides[4].activeIngredients.map(
      (ingredient, index) => {
        return {
          id: index,
          name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
        }
      }
    ),
    doses: {
      soybean_asian_soybean_rust: {
        target_id: seasonDiseases.soybean[0].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_anthracnosis: {
        target_id: seasonDiseases.soybean[1].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_target_spot: {
        target_id: seasonDiseases.soybean[2].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_powdery_mildew: {
        target_id: seasonDiseases.soybean[3].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_cercospora_blight: {
        target_id: seasonDiseases.soybean[4].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_septoria_leaf_spot: {
        target_id: seasonDiseases.soybean[5].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
      soybean_white_mold: {
        target_id: seasonDiseases.soybean[6].id,
        has_register: true,
        recommended_dose: randomRecommendedDose(),
        recommended_dose_max: randomRecommendedMaxDose(),
      },
    },
    efficacies: getRandomEfficacies([
      seasonDiseases.soybean[0],
      seasonDiseases.soybean[1],
      seasonDiseases.soybean[2],
      seasonDiseases.soybean[3],
      seasonDiseases.soybean[4],
      seasonDiseases.soybean[5],
      seasonDiseases.soybean[6],
    ]),
    mixtures: [
      {
        defensive_id: catalogFungicides[2].pesticide_id,
        defensive_name: catalogFungicides[2].pesticide,
        active_ingredients: catalogFungicides[2].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
      {
        defensive_id: catalogFungicides[3].pesticide_id,
        defensive_name: catalogFungicides[3].pesticide,
        active_ingredients: catalogFungicides[3].activeIngredients.map(
          (ingredient, index) => {
            return {
              id: index,
              name: i18n.t(`Catalog.Options.${ingredient.active_ingredient}`),
            }
          }
        ),
        efficacies: getRandomEfficacies([
          seasonDiseases.soybean[0],
          seasonDiseases.soybean[1],
          seasonDiseases.soybean[2],
          seasonDiseases.soybean[3],
          seasonDiseases.soybean[4],
          seasonDiseases.soybean[5],
          seasonDiseases.soybean[6],
        ]),
      },
    ],
  },
]

export function getRandomEfficacies(diseases) {
  const efficacies = diseases.map((disease) => {
    return {
      disease_id: disease.id,
      disease_name: disease.code,
      efficacy: Math.random(),
    }
  })
  return efficacies
}

function randomRecommendedDose() {
  return Math.floor(Math.random() * 6)
}

function randomRecommendedMaxDose() {
  return Math.floor(Math.random() * 4) + 5
}

export { pesticides, seasonDiseases }
