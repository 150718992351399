const getCustomDate = (days, hoursFormat = false) => {
  const now = new Date()

  now.setDate(now.getDate() + days)

  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')

  if (hoursFormat)
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  else return `${year}-${month}-${day}`
}

const isDateBetween = (
  currentDate,
  monthsBehind,
  monthsAhead,
  startDate,
  endDate
) => {
  const now = new Date()

  let calculatedStartDate = new Date(
    now.getFullYear(),
    now.getMonth() - monthsBehind,
    now.getDate()
  )
  let calculatedEndDate = new Date(
    now.getFullYear(),
    now.getMonth() + monthsAhead,
    now.getDate()
  )

  if (startDate && endDate) {
    calculatedStartDate = new Date(startDate)
    calculatedEndDate = new Date(endDate)
  }
  const date = new Date(currentDate + 'T00:00:00')
  const isBetween = date >= calculatedStartDate && date < calculatedEndDate

  return isBetween
}

export { getCustomDate, isDateBetween }
