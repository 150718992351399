// FIX-ME: Trazer essas informações de um endpoint, somente onde for usar
// https://github.com/orgs/DigiFarmz/projects/4/views/2?filterQuery=sprint%3A%22Sprint+43%22+&pane=issue&itemId=58254949
const soybeanIcon = {
  alt: 'soybean',
  src: 'ic-soybean.svg',
}

const wheatIcon = {
  alt: 'wheat',
  src: 'ic-wheat.svg',
}

export const SOYBEAN_ID = 1
export const WHEAT_ID = 2

export const CROP = {
  SOYBEAN: 1,
  WHEAT: 2,
}
export const crops = [
  {
    id: CROP.SOYBEAN,
    name: 'crop.soybean',
    icon: soybeanIcon,
  },
  {
    id: CROP.WHEAT,
    name: 'crop.wheat',
    icon: wheatIcon,
  },
]

export const getCropNameById = (cropId) => {
  const currentCrop = crops.find(({ id }) => id == cropId) ?? null
  if (Object.keys(currentCrop).length) {
    return currentCrop.name
  }
  return ''
}

export const getCropIconById = (cropId) => {
  const currentCrop = crops.find(({ id }) => id == cropId) ?? null
  if (Object.keys(currentCrop).length) {
    return {
      alt: currentCrop.icon.alt,
      src: require(`@/assets/icons/${currentCrop.icon.src}`),
    }
  }
  return ''
}

export const translateCropName = (cropName) => {
  const translations = {
    milho: 'corn',
    maíz: 'corn',
    corn: 'corn',
    soja: 'soybean',
    soybean: 'soybean',
    trigo: 'wheat',
    wheat: 'wheat',
  }
  return translations[cropName]
}
